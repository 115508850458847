
<template>
  <div class="record-bg">
    <div
      class="empty"
      v-if="emptyPage"
    >
      <img
        src="@/assets/images/dealer/empty.png"
        alt=""
      >
      <p>暂无邀请记录</p>
    </div>
    <div v-else>
      <div class="record-box">
        <div
          v-for="(item , index) in dealerList"
          :key="index"
        >
          <div class="record-list">
            <div class="record">
              <div class="record-text">{{item.dealerName}}</div>
              <div
                v-if="item.authenticationStatus === 1"
                class="record-red"
              >未注册</div>
              <div
                v-if="item.authenticationStatus === 2"
                class="record-bule"
              >认证中</div>
              <div
                v-if="item.authenticationStatus === 3"
                class="record-gray"
              >认证成功</div>
              <div
                v-if="item.authenticationStatus === 4"
                class="record-red"
              >认证失败</div>
            </div>
            <div
              class="record-information"
              v-if="item.authenticationStatus === 4"
            >快去让他修改注册资料，重新提交吧~</div>
            <div
              class="record-information"
              v-if="item.authenticationStatus === 1"
            >记得通知他，快点去注册哦~</div>
            <div class="record-information">接受邀请时间：{{formatTime(item.invitedTime)}}</div>
          </div>
        </div>
      </div>
      <p
        class="text"
        v-if="dealerList.length > 0"
      >没有更多了</p>
    </div>
  </div>

</template>
<script>
import { dealerList } from 'api/dealer'
import { setSessionStorage } from 'utils/store'
export default {
  data () {
    return {
      emptyPage: false,
      dealerList: [],
      garageId: ''
    }
  },
  beforeCreate () {
    setSessionStorage('token', this.$route.query.token)
  },
  mounted () {
    this.getDealerList()
    this.garageId = this.$route.query.garageId
    this.getDealerList(this.garageId)
  },
  methods: {
    getDealerList () {
      dealerList().then((res) => {
        let { data, result, desc } = res.data
        if (result !== 0) {
          this.toast = this.$createToast({
            txt: desc,
            type: 'error'
          })
          this.toast.show()
          this.emptyPage = true
          return
        }
        this.dealerList = data.page
        if (this.dealerList.length < 1 || this.dealerList === {}) {
          this.emptyPage = true
        } else {
          this.emptyPage = false
        }
      })
    },
    formatTime (time = '') {
      let timeArray = time.split(' ')
      let str = ''
      str = timeArray[0] + ' ' + timeArray[1].substring(0, 5)
      return str
    }

  }
}
</script>

 <style lang="scss" scoped>
@import "~styles/index.scss";
.record-bg {
  background: #f0f0f0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.record-box {
  // width: px2rem(720px);
  width: 96%;
  padding-left: px2rem(30px);
  background: #fff;
}
.record-list {
  padding: px2rem(30px) 0;
  border-bottom: 1px solid #e4e4e4;
  font-size: px2rem(28px);
}
.record {
  display: flex;
  justify-content: space-between;
  // padding-right: px2rem(30px);
  padding-bottom: px2rem(15px);
  width: 100%;
}
.record-information {
  width: 100%;
  display: block;
  font-size: px2rem(24px);
  color: #999999;
  padding-top: px2rem(10px);
}
.record-bule {
  color: #4081d6;
  padding-right: px2rem(30px);
}
.record-red {
  color: #fc4c5a;
  padding-right: px2rem(30px);
}
.record-gray {
  color: #363636;
  padding-right: px2rem(30px);
}
.text {
  font-size: px2rem(24px);
  color: #999;
  padding-top: px2rem(40px);
  // width: px2rem(750px);
  width: 100%;
  text-align: center;
}
.empty {
  font-size: px2rem(28px);
  text-align: center;
  // width: px2rem(750px);
  width: 100%;
  padding-top: px2rem(260px);
  img {
    width: px2rem(204px);
    height: px2rem(187px);
  }
  p {
    color: #999;
    padding-top: px2rem(24px);
  }
}
.record-text {
  width: px2rem(500px);
  // overflow: hidden;
  height: px2rem(28px);
}
</style>
  