/*
 * @Author: xuhongwei
 * @Date: 2019-07-01 14:55:39
 * @LastEditTime: 2020-03-04 11:28:25
 * @LastEditors: xuhongwei
 * @Description: 
 */
import ajax from './axios'
// 根据Id查询门店信息
export const storeInformation = id => {
  return ajax.get(`/garage/garage/manage/${id}/garageDetailH5`)
}

// 立即参与按钮
export const dealerCheck = params => {
  return ajax.post('/abaddon/invite/dealer/register', params)
}

// 发送验证码
export const dealerCode = params => {
  return ajax.post('/abaddon/invite/dealer/code', params)
}

// 邀请记录
export const dealerList = params => {
  return ajax.get('/user/garage/invite/dealer/record?pageNum=1&pageSize=50', params)
}

// 图形验证码
export const getDealerImageCode = phone => {
  return ajax.get(`/abaddon/invite/dealer/refresh/img/code?phone=${phone}`)
}

// 营业报表汇总数据
export const getDelalerPerformance = params => {
  return ajax.post('/order/service/report/statistics', params)
}

// 月营业额图表
export const getDelalerPerformanceSummary = params => {
  return ajax.post('/order/service/report/statistics/newest', params)
}

// 查询门店库存管理状态
export const getStockStatus = params => {
  return ajax.get('/user/garage/stock/manage/status', params)
}

// 报表统计-查询营业统计明细（app）
export const getBusinessStatistics = params => {
  return ajax.post('/order/service/report/business/statistics', params)
}

// 报表统计-查询配件毛利（app）
export const getAccessoriesStatistics = params => {
  return ajax.post('/order/service/report/part/statistics', params)
}

// 报表统计-车辆统计（app）
export const getVehicleStatistics = params => {
  return ajax.post('/order/service/report/car/statistics', params)
}

// 报表统计-库存统计（app）
export const getStockStatistics = params => {
  return ajax.post('/order/service/report/stock/statistics', params)
}

// 报表统计-技师统计明细（app）
export const getTechniciansStatistics = params => {
  return ajax.post('/order/service/report/technician/statistics', params)
}

// 报表统计-技师统计明细（app v2.11）
export const getTechniciansStatisticsNew = params => {
  return ajax.post('/order/service/report/technician/statistics/2.11', params)
}
// 报表统计-技师统计明细（app）
export const getTechniciansStatisticsDetail = (params, page) => {
  return ajax.post(`/order/service/report/technician/statistics/detail?pageNum=${page.pageNum}&pageSize=${page.pageSize}`, params)
}

// 报表统计-是否开通钱包（app）
export const getGarageWalletStatus = () => {
  return ajax.get(`/user/merchant/account/isOpen`)
}
